<template>
  <div>
    <div class="text-h4">
      Account Summary
    </div>
    <v-divider class="mb-4 mt-2" />
    <v-row>
      <v-col
        v-if="showViewAsCustomer"
        cols="12"
        sm="4"
        lg="3"
        class="pb-1"
      >
        <ViewAsCustomer />
      </v-col>
      <v-col
        cols="12"
        sm="4"
        lg="3"
        class="pb-1"
      >
        <v-menu
          v-model="dateRange.dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText(dateRange.dates)"
              class="pt-0"
              label="Month range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateRange.dates"
            range
            type="month"
            :min="minMonth.local().format()"
            :max="currentMonth"
            show-current
          />
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        sm="4"
        lg="3"
        class="pb-1"
      >
        <v-select
          v-model="widgetsSelected"
          :items="widgets"
          label="Widgets"
          item-text="text"
          item-value="value"
          dense
          hide-details
          outlined
          multiple
        >
          <template v-slot:selection="{ index }">
            <span
              v-if="index === 0"
            >
              {{ widgetsSelected.length }} selected
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="widgetsSelected.includes('Sent')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Sent').text"
          name="Sent"
          mailing-service-method="getReportSentWidget"
          icon="mdi-send-check"
          icon-two="mdi-arrow-u-down-right-bold"
          icon-three="mdi-arrow-u-down-right"
          info-description="The number of sent emails."
          info-description-two="Hard Bounce numbers (as a % of your sent emails)."
          info-description-three="Soft Bounce numbers (as a % of your sent emails)."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Received')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Received').text"
          name="Received"
          icon="mdi-email-check-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of recipients that received your emails (as a % of your sent emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Optouts')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Optouts').text"
          name="Optouts"
          icon="mdi-email-alert-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of opt-outs generated from your emails (as a % of your delivered emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Opens')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Opens').text"
          name="Opens"
          icon="mdi-email-open-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of recipients that opened your emails (as a % of your delivered emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Clicks')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Clicks').text"
          name="Clicks"
          icon="mdi-cursor-default-click-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of recipients that clicked on a link in your emails (as a % of your delivered emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('Replies')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'Replies').text"
          name="Replies"
          info-description="The number of replies from your emails (as a % of your delivered emails)."
          icon="mdi-reply"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('ReadEng')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          display-name="Average Read Engagement"
          name="ReadEng"
          icon="mdi-book-open-page-variant-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The average number of seconds recipients spent reading your emails."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          mailing-service-method="getReportAvgReadSecsWidget"
          appended-string=" s"
          hide-counts
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('ReadEnv')"
        cols="12"
        md="6"
        lg="4"
      >
        <StringMetricsWidget
          :display-name="widgets.find(x => x.value === 'ReadEnv').text"
          name="ReadEnv"
          mailing-service-method="getReportReadEnvWidget"
          info-description="The most popular reading environment opened by your recipients (as a % of all recorded reading environments)."
          icon="mdi-numeric-1-box-outline"
          icon-two="mdi-numeric-2-box-outline"
          icon-three="mdi-numeric-3-box-outline"
          info-description-two="2nd most popular reading environment."
          info-description-three="3rd most popular reading environment."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <v-col
        v-if="widgetsSelected.includes('MailClients')"
        cols="12"
        md="6"
        lg="4"
      >
        <StringMetricsWidget
          :display-name="widgets.find(x => x.value === 'MailClients').text"
          name="MailClients"
          mailing-service-method="getReportMailClientsWidget"
          info-description="The most popular mail client opened by your recipients (as a % of all recorded mail clients)."
          icon="mdi-at"
          icon-two="mdi-numeric-2-box-outline"
          icon-three="mdi-numeric-3-box-outline"
          info-description-two="2nd most popular mail client."
          info-description-three="3rd most popular mail client."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col>
      <!-- <v-col
        v-if="widgetsSelected.includes('MailBlocks')"
        cols="12"
        md="6"
        lg="4"
      >
        <BasicMetricsWidget
          :display-name="widgets.find(x => x.value === 'MailBlocks').text"
          name="MailBlocks"
          icon="mdi-email-remove-outline"
          icon-two="mdi-email-outline"
          icon-three="mdi-email-sync-outline"
          info-description="The number of your emails that were blocked (as a % of your delivered emails)."
          info-description-two="Initial broadcast numbers."
          info-description-three="Resend broadcast numbers."
          :dates="dateRange.dates"
          @viewDetails="viewDetails"
        />
      </v-col> -->
      <v-col
        id="detailSection"
        cols="12"
      >
        <v-row
          v-if="showDetails"
          align="center"
          no-gutters
        >
          <v-col
            class="px-0"
          >
            <v-divider
              class="my-2"
            />
          </v-col>
          <v-col
            class="pr-0 pl-2"
            cols="auto"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="$_downloadFileMixin_downloadReportChart(resultDetailsChart, selectedMetricDetail.append, `${selectedMetricDetail.text.replace(/ /g, '')}-${dateRangeText(dateRange.dates).replace(/ /g, '')}`, true)"
                >
                  <v-icon color="green lighten-3">
                    mdi-file-download
                  </v-icon>
                </v-btn>
              </template>
              <span>Download Details</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="closeDetails"
                >
                  <v-icon color="tertiary">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close Details</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <bar-chart
          v-if="showDetails && !detailsLoading && resultDetailsChart"
          :chart-data="resultDetailsChart"
          :options="getChartOptionsBar(showDetailsMetric)"
          :height="chartHeight"
        />
        <v-skeleton-loader
          v-else-if="showDetails"
          type="image@2"
        />
      </v-col>
    </v-row>
    <div class="text-h4 mt-8">
      Reports
    </div>
    <v-divider class="mb-4 mt-2" />
    <v-row>
      <v-col
        v-if="R_EAR"
        cols="12"
        sm="6"
        md="3"
      >
        <EmailAnalyticsReport />
      </v-col>
      <v-col
        v-if="R_DMAR"
        cols="12"
        sm="6"
        md="3"
      >
        <DirectMailAnalyticsReport />
      </v-col>
      <v-col
        v-if="MMS_TEBT"
        cols="12"
        sm="6"
        md="3"
      >
        <PubApiUsageReport />
      </v-col>
      <!-- <v-col
        v-if="$_global_hasRouteClaims('CombinedCampaignReport')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Combined Campaign Report
          </v-card-title>
          <v-divider />
          <v-card-text>
            View data for multiple campaigns.
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'CombinedCampaignReport' }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col> -->
      <v-col
        v-if="$_global_hasRouteClaims('ServiceLineSpecialtyReport')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Service Line/Specialty
            <v-spacer />
            <v-tooltip
              top
              z-index="15"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="info"
                  v-on="on"
                >
                  {{ biIcon }}
                </v-icon>
              </template>
              <span>{{ biDescription }}</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <v-card-text>
            View Service Line/Specialty breakdown and compare to your previous data (or MMS benchmark data).
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'ServiceLineSpecialtyReport' }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="$_global_hasRouteClaims('ServiceLineOfferTypeReport')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Service Line/Offer Type
            <v-spacer />
            <v-tooltip
              top
              z-index="15"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="info"
                  v-on="on"
                >
                  {{ biIcon }}
                </v-icon>
              </template>
              <span>{{ biDescription }}</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <v-card-text>
            View Service Line/Offer Type breakdown and compare to your previous data (or MMS benchmark data).
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'ServiceLineOfferTypeReport' }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="$_global_hasRouteClaims('SpecialtyEngagementReport')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Specialty Engagement
            <v-spacer />
            <v-tooltip
              top
              z-index="15"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="info"
                  v-on="on"
                >
                  {{ biIcon }}
                </v-icon>
              </template>
              <span>{{ biDescription }}</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <v-card-text>
            View Specialty Engagement breakdown and compare to your previous data (or MMS benchmark data).
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'SpecialtyEngagementReport' }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="$_global_hasRouteClaims('TopPerformersReport')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Top/Bottom Campaigns
            <v-spacer />
            <v-tooltip
              top
              z-index="15"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="info"
                  v-on="on"
                >
                  {{ biIcon }}
                </v-icon>
              </template>
              <span>{{ biDescription }}</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <v-card-text>
            View your top/bottom performing campaigns to gain insight on audience engagement.
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'TopPerformersReport' }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Top/Bottom Subject Lines
            <v-spacer />
            <v-tooltip
              top
              z-index="15"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="info"
                  v-on="on"
                >
                  {{ biIcon }}
                </v-icon>
              </template>
              <span>{{ biDescription }}</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <v-card-text>
            View the top/bottom Subject Line Performers to see how different subject lines affect your campaigns.
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'TopPerformersReport', query: {subject: 'subjectLine'} }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Best Day To Send
            <v-spacer />
            <v-tooltip
              top
              z-index="15"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="info"
                  v-on="on"
                >
                  {{ biIcon }}
                </v-icon>
              </template>
              <span>{{ biDescription }}</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <v-card-text>
            View by file type and specialty to see the best days when recipients are engaging with your campaigns.
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'BestDayReport' }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-title class="text-h5">
            Engagement Comparison
            <v-spacer />
            <v-tooltip
              top
              z-index="15"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  color="info"
                  v-on="on"
                >
                  {{ biIcon }}
                </v-icon>
              </template>
              <span>{{ biDescription }}</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <v-card-text>
            View your campaign engagement metrics compared to previous campaign metrics from specified date ranges.
          </v-card-text>
          <v-spacer />
          <v-card-actions class="flex-row-reverse">
            <router-link :to="{ name: 'DashboardCompare' }">
              <v-btn color="primary">
                View
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  inputMonthFormat,
  displayMonthFormat,
  inputDateFormat
} from '@/shared/constants'
import mutationTypes from '@/store/mutation-types'
import { mapGetters } from 'vuex'
import claims from '@/shared/models/auth/claims'
import { mailingService } from '@/shared/services'
import { downloadFileMixin } from '@/shared/mixins/general'
import moment from 'moment'

export default {

  name: 'ReportDashboard',

  components: {
    ViewAsCustomer: () => import('@/views/pages/components/user-state/ViewAsCustomer'),
    EmailAnalyticsReport: () => import('@/views/pages/reports/EmailAnalyticsReport.vue'),
    DirectMailAnalyticsReport: () => import('@/views/pages/reports/DirectMailAnalyticsReport.vue'),
    PubApiUsageReport: () => import('@/views/pages/reports/PubApiUsageReport.vue'),
    BasicMetricsWidget: () => import('@/views/pages/reports/dashboard-widgets/BasicMetricsWidget.vue'),
    StringMetricsWidget: () => import('@/views/pages/reports/dashboard-widgets/StringMetricsWidget.vue'),
    BarChart: () => import('@/views/pages/components/charts/BarChart')
  },

  mixins: [downloadFileMixin],

  data () {
    return {
      widgets: [
        {
          text: 'Delivered',
          value: 'Received',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 50,
          // chartScaleMax: 100,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Opens',
          value: 'Opens',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 50,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Clicks',
          value: 'Clicks',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 30,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Average Read Engagement',
          value: 'ReadEng',
          detailMethod: 'getReportAvgReadSecsWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 60,
          xAxisLabel: 'Month/Year',
          append: 's',
          precision: 1
        },
        {
          text: 'Sent/Bounces',
          value: 'Sent',
          detailMethod: 'getReportSentWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 50,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Opt-outs',
          value: 'Optouts',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 5,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        // {
        //   text: 'Mail Blocks',
        //   value: 'MailBlocks',
        //   detailMethod: 'getReportMetricWidgetDetails',
        //   // chartScaleMin: 0,
        //   // chartScaleMax: 50,
        //   xAxisLabel: 'Month/Year',
        //   append: '%',
        //   precision: 2
        // },
        {
          text: 'Replies',
          value: 'Replies',
          detailMethod: 'getReportMetricWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 5,
          xAxisLabel: 'Month/Year',
          append: '%',
          precision: 2
        },
        {
          text: 'Mail Clients',
          value: 'MailClients',
          detailMethod: 'getReportMailClientsWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 100,
          xAxisLabel: 'Mail Client',
          append: '%',
          precision: 2
        },
        {
          text: 'Read Environments',
          value: 'ReadEnv',
          detailMethod: 'getReportReadEnvWidgetDetails',
          // chartScaleMin: 0,
          // chartScaleMax: 100,
          xAxisLabel: 'Device',
          append: '%',
          precision: 2
        }
      ],
      dateRange: {
        dateMenu: false,
        dates: [
          moment().subtract(1, 'month').local().format(inputMonthFormat),
          moment().local().format(inputMonthFormat)
        ]
      },
      currentMonth: moment().local().format(),
      unsubscribe: null,
      chartHeight: 400,
      showDetails: false,
      showDetailsMetric: 'Delivered',
      detailMetric: 'Received',
      detailsLoading: false,
      resultDetailsChart: null,
      biDescription: 'Broadcast Intelligence Report',
      biIcon: 'mdi-chart-areaspline'
    }
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    widgetsSelected: {
      get () {
        return this.$store.getters.reportWidgets()
      },
      set (value) {
        this.$store.dispatch('setReportWidgets', value)
      }
    },
    selectedMetricDetail () {
      return this.widgets.find(x => x.value === this.detailMetric)
    },
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    MMS_TEBT () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    R_DMAR () {
      return this.hasClaimKV(claims.R_DMAR)
    },
    R_EAR () {
      return this.hasClaimKV(claims.R_EAR)
    },
    minMonth () {
      return moment().subtract(24, 'months')
    }
  },

  watch: {
    'dateRange.dates' (newValue, oldValue) {
      if (newValue && newValue.length === 2) {
        if (moment(newValue[0]).isAfter(moment(newValue[1]))) {
          this.dateRange.dates = [
            newValue[1],
            newValue[0]
          ]
          return
        }
        if (this.showDetails) this.loadViewDetails()
      }
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type) && this.showDetails) {
        this.loadViewDetails()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    dateRangeText (dates) {
      if (!dates || dates.length !== 2) return ''
      if (moment(dates[0]).isSame(moment(dates[1]), 'month')) return moment(dates[0]).format(displayMonthFormat)
      return dates.map(x => moment(x).format(displayMonthFormat)).join(' - ')
    },
    closeDetails () {
      this.showDetails = false
      this.$vuetify.goTo(0, { offset: 0, easing: 'easeInCubic', duration: 400 })
    },
    async viewDetails (metric) {
      if (this.dateRange.dates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      this.detailMetric = metric
      this.showDetailsMetric = this.widgets.find(x => x.value === metric).text
      this.$vuetify.goTo('#detailSection', { offset: this.chartHeight, easing: 'easeInCubic', duration: 400 })
      await this.loadViewDetails()
    },
    async loadViewDetails () {
      if (this.dateRange.dates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      var _this = this
      this.detailsLoading = true
      this.showDetails = true
      await mailingService[this.selectedMetricDetail.detailMethod]({
        customerNowwId: this.$store.getters['simulatedCustomerNowwId'](),
        metricName: this.detailMetric,
        startDate: moment(this.dateRange.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(this.dateRange.dates[1]).endOf('month').format(inputDateFormat)
      }).then(function (resp) {
        if (resp.errorResponse) {
          _this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          _this.resultDetailsChart = resp
        }
      })
        .finally(() => {
          this.detailsLoading = false
        })
    },
    getChartOptionsBar (title) {
      var append = this.selectedMetricDetail.append
      var precision = this.selectedMetricDetail.precision
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: `${title} from ${this.dateRangeText(this.dateRange.dates)}`
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              callback: function (value, index, values) {
                return `${value}${append}`
              },
              min: 0
              // min: this.selectedMetricDetail.chartScaleMin,
              // max: this.selectedMetricDetail.chartScaleMax
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: this.selectedMetricDetail.xAxisLabel
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       if (!value || value <= 0) return ''
        //       return `${parseFloat(value).toFixed(precision)}${append}`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    }
  }

}
</script>

<style lang="scss">
.v-card__title {
  word-break: normal;
}
</style>
