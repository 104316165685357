var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-h4" }, [
        _vm._v("\n    Account Summary\n  ")
      ]),
      _c("v-divider", { staticClass: "mb-4 mt-2" }),
      _c(
        "v-row",
        [
          _vm.showViewAsCustomer
            ? _c(
                "v-col",
                {
                  staticClass: "pb-1",
                  attrs: { cols: "12", sm: "4", lg: "3" }
                },
                [_c("ViewAsCustomer")],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "12", sm: "4", lg: "3" } },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pt-0",
                                  attrs: {
                                    value: _vm.dateRangeText(
                                      _vm.dateRange.dates
                                    ),
                                    label: "Month range",
                                    "prepend-icon": "mdi-calendar-range",
                                    readonly: "",
                                    "single-line": "",
                                    "hide-details": ""
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dateRange.dateMenu,
                    callback: function($$v) {
                      _vm.$set(_vm.dateRange, "dateMenu", $$v)
                    },
                    expression: "dateRange.dateMenu"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      range: "",
                      type: "month",
                      min: _vm.minMonth.local().format(),
                      max: _vm.currentMonth,
                      "show-current": ""
                    },
                    model: {
                      value: _vm.dateRange.dates,
                      callback: function($$v) {
                        _vm.$set(_vm.dateRange, "dates", $$v)
                      },
                      expression: "dateRange.dates"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "12", sm: "4", lg: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.widgets,
                  label: "Widgets",
                  "item-text": "text",
                  "item-value": "value",
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  multiple: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var index = ref.index
                      return [
                        index === 0
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.widgetsSelected.length) +
                                  " selected\n          "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.widgetsSelected,
                  callback: function($$v) {
                    _vm.widgetsSelected = $$v
                  },
                  expression: "widgetsSelected"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.widgetsSelected.includes("Sent")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("BasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Sent"
                      }).text,
                      name: "Sent",
                      "mailing-service-method": "getReportSentWidget",
                      icon: "mdi-send-check",
                      "icon-two": "mdi-arrow-u-down-right-bold",
                      "icon-three": "mdi-arrow-u-down-right",
                      "info-description": "The number of sent emails.",
                      "info-description-two":
                        "Hard Bounce numbers (as a % of your sent emails).",
                      "info-description-three":
                        "Soft Bounce numbers (as a % of your sent emails).",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Received")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("BasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Received"
                      }).text,
                      name: "Received",
                      icon: "mdi-email-check-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of recipients that received your emails (as a % of your sent emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Optouts")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("BasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Optouts"
                      }).text,
                      name: "Optouts",
                      icon: "mdi-email-alert-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of opt-outs generated from your emails (as a % of your delivered emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Opens")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("BasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Opens"
                      }).text,
                      name: "Opens",
                      icon: "mdi-email-open-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of recipients that opened your emails (as a % of your delivered emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Clicks")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("BasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Clicks"
                      }).text,
                      name: "Clicks",
                      icon: "mdi-cursor-default-click-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The number of recipients that clicked on a link in your emails (as a % of your delivered emails).",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("Replies")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("BasicMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "Replies"
                      }).text,
                      name: "Replies",
                      "info-description":
                        "The number of replies from your emails (as a % of your delivered emails).",
                      icon: "mdi-reply",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("ReadEng")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("BasicMetricsWidget", {
                    attrs: {
                      "display-name": "Average Read Engagement",
                      name: "ReadEng",
                      icon: "mdi-book-open-page-variant-outline",
                      "icon-two": "mdi-email-outline",
                      "icon-three": "mdi-email-sync-outline",
                      "info-description":
                        "The average number of seconds recipients spent reading your emails.",
                      "info-description-two": "Initial broadcast numbers.",
                      "info-description-three": "Resend broadcast numbers.",
                      "mailing-service-method": "getReportAvgReadSecsWidget",
                      "appended-string": " s",
                      "hide-counts": "",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("ReadEnv")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("StringMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "ReadEnv"
                      }).text,
                      name: "ReadEnv",
                      "mailing-service-method": "getReportReadEnvWidget",
                      "info-description":
                        "The most popular reading environment opened by your recipients (as a % of all recorded reading environments).",
                      icon: "mdi-numeric-1-box-outline",
                      "icon-two": "mdi-numeric-2-box-outline",
                      "icon-three": "mdi-numeric-3-box-outline",
                      "info-description-two":
                        "2nd most popular reading environment.",
                      "info-description-three":
                        "3rd most popular reading environment.",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widgetsSelected.includes("MailClients")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("StringMetricsWidget", {
                    attrs: {
                      "display-name": _vm.widgets.find(function(x) {
                        return x.value === "MailClients"
                      }).text,
                      name: "MailClients",
                      "mailing-service-method": "getReportMailClientsWidget",
                      "info-description":
                        "The most popular mail client opened by your recipients (as a % of all recorded mail clients).",
                      icon: "mdi-at",
                      "icon-two": "mdi-numeric-2-box-outline",
                      "icon-three": "mdi-numeric-3-box-outline",
                      "info-description-two": "2nd most popular mail client.",
                      "info-description-three": "3rd most popular mail client.",
                      dates: _vm.dateRange.dates
                    },
                    on: { viewDetails: _vm.viewDetails }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { id: "detailSection", cols: "12" } },
            [
              _vm.showDetails
                ? _c(
                    "v-row",
                    { attrs: { align: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-0" },
                        [_c("v-divider", { staticClass: "my-2" })],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pr-0 pl-2", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  _vm.$_downloadFileMixin_downloadReportChart(
                                                    _vm.resultDetailsChart,
                                                    _vm.selectedMetricDetail
                                                      .append,
                                                    _vm.selectedMetricDetail.text.replace(
                                                      / /g,
                                                      ""
                                                    ) +
                                                      "-" +
                                                      _vm
                                                        .dateRangeText(
                                                          _vm.dateRange.dates
                                                        )
                                                        .replace(/ /g, ""),
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "green lighten-3"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-file-download\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                443820477
                              )
                            },
                            [_c("span", [_vm._v("Download Details")])]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { icon: "" },
                                              on: { click: _vm.closeDetails }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "tertiary" } },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-close\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2348375109
                              )
                            },
                            [_c("span", [_vm._v("Close Details")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showDetails && !_vm.detailsLoading && _vm.resultDetailsChart
                ? _c("bar-chart", {
                    attrs: {
                      "chart-data": _vm.resultDetailsChart,
                      options: _vm.getChartOptionsBar(_vm.showDetailsMetric),
                      height: _vm.chartHeight
                    }
                  })
                : _vm.showDetails
                ? _c("v-skeleton-loader", { attrs: { type: "image@2" } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "text-h4 mt-8" }, [_vm._v("\n    Reports\n  ")]),
      _c("v-divider", { staticClass: "mb-4 mt-2" }),
      _c(
        "v-row",
        [
          _vm.R_EAR
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [_c("EmailAnalyticsReport")],
                1
              )
            : _vm._e(),
          _vm.R_DMAR
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [_c("DirectMailAnalyticsReport")],
                1
              )
            : _vm._e(),
          _vm.MMS_TEBT
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [_c("PubApiUsageReport")],
                1
              )
            : _vm._e(),
          _vm.$_global_hasRouteClaims("ServiceLineSpecialtyReport")
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h5" },
                        [
                          _vm._v(
                            "\n          Service Line/Specialty\n          "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "z-index": "15" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            { attrs: { color: "info" } },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.biIcon) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2939256566
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.biDescription))])]
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c("v-card-text", [
                        _vm._v(
                          "\n          View Service Line/Specialty breakdown and compare to your previous data (or MMS benchmark data).\n        "
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-card-actions",
                        { staticClass: "flex-row-reverse" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "ServiceLineSpecialtyReport" }
                              }
                            },
                            [
                              _c("v-btn", { attrs: { color: "primary" } }, [
                                _vm._v("\n              View\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$_global_hasRouteClaims("ServiceLineOfferTypeReport")
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h5" },
                        [
                          _vm._v(
                            "\n          Service Line/Offer Type\n          "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "z-index": "15" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            { attrs: { color: "info" } },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.biIcon) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2939256566
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.biDescription))])]
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c("v-card-text", [
                        _vm._v(
                          "\n          View Service Line/Offer Type breakdown and compare to your previous data (or MMS benchmark data).\n        "
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-card-actions",
                        { staticClass: "flex-row-reverse" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "ServiceLineOfferTypeReport" }
                              }
                            },
                            [
                              _c("v-btn", { attrs: { color: "primary" } }, [
                                _vm._v("\n              View\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$_global_hasRouteClaims("SpecialtyEngagementReport")
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h5" },
                        [
                          _vm._v(
                            "\n          Specialty Engagement\n          "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "z-index": "15" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            { attrs: { color: "info" } },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.biIcon) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2939256566
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.biDescription))])]
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c("v-card-text", [
                        _vm._v(
                          "\n          View Specialty Engagement breakdown and compare to your previous data (or MMS benchmark data).\n        "
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-card-actions",
                        { staticClass: "flex-row-reverse" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "SpecialtyEngagementReport" }
                              }
                            },
                            [
                              _c("v-btn", { attrs: { color: "primary" } }, [
                                _vm._v("\n              View\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$_global_hasRouteClaims("TopPerformersReport")
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "3" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h5" },
                        [
                          _vm._v(
                            "\n          Top/Bottom Campaigns\n          "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "z-index": "15" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            { attrs: { color: "info" } },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.biIcon) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2939256566
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.biDescription))])]
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c("v-card-text", [
                        _vm._v(
                          "\n          View your top/bottom performing campaigns to gain insight on audience engagement.\n        "
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-card-actions",
                        { staticClass: "flex-row-reverse" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "TopPerformersReport" } } },
                            [
                              _c("v-btn", { attrs: { color: "primary" } }, [
                                _vm._v("\n              View\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5" },
                    [
                      _vm._v(
                        "\n          Top/Bottom Subject Lines\n          "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g({ attrs: { color: "info" } }, on),
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.biIcon) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.biDescription))])]
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          View the top/bottom Subject Line Performers to see how different subject lines affect your campaigns.\n        "
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-card-actions",
                    { staticClass: "flex-row-reverse" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "TopPerformersReport",
                              query: { subject: "subjectLine" }
                            }
                          }
                        },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v("\n              View\n            ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5" },
                    [
                      _vm._v("\n          Best Day To Send\n          "),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g({ attrs: { color: "info" } }, on),
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.biIcon) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.biDescription))])]
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          View by file type and specialty to see the best days when recipients are engaging with your campaigns.\n        "
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-card-actions",
                    { staticClass: "flex-row-reverse" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "BestDayReport" } } },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v("\n              View\n            ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5" },
                    [
                      _vm._v("\n          Engagement Comparison\n          "),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g({ attrs: { color: "info" } }, on),
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.biIcon) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.biDescription))])]
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          View your campaign engagement metrics compared to previous campaign metrics from specified date ranges.\n        "
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-card-actions",
                    { staticClass: "flex-row-reverse" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "DashboardCompare" } } },
                        [
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v("\n              View\n            ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }